import * as React from 'react'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Container from '../../components/container'
import DemoForm from '../../components/demo_form'
import Link from '../../components/link'
import HiwSubnav from '../../components/hiw_subnav'
import { StaticImage } from 'gatsby-plugin-image'
import NumberedScroll from '../../components/numbered_scroll'
import CheckIn1Image from '../../images/HIW_Showroom_CheckIn_01.png'
import CheckIn2Image from '../../images/HIW_Showroom_CheckIn_02.png'
import ComparePaymentsImage from '../../images/HIW_Showroom_ComparePayments.png'
import SaveDealsImage from '../../images/HIW_Showroom_SaveDeals.png'
import Box from '../../components/box'

const HiwShowroomToolsPage = () => (
  <Layout activeNav={true}>
    <Seo
      title='Showroom Tools'
      description=''
    />
    <div className='flex flex-col justify-center relative bg-black text-white'>
      <StaticImage
        src='../../images/HIW_ShowroomTools_Hero.jpg'
        placeholder='blurred'
        alt=''
        className='absolute inset-0'
      />
      <Container className='relative pb-4 pt-5 sm:pb-5 text-white'>
        <h1 className='font-semi-bold text-4vw'>
          Showroom Tools
        </h1>
        <p className='text-md sm:text-lg mb-0' style={{ maxWidth: '42em' }}>
          Sales agents can use the same Express Storefront platform to transact in store that customers and internet/BDC teams use online. Enhanced with showroom-specific features, it enables them to build trust — along with deals — side by side with customers at a kiosk or on a tablet.
        </p>
      </Container>
    </div>
    <HiwSubnav />
    <div className='py-3 sm:py-4'>
      <Container size='lg'>
        <NumberedScroll
          items={[
            {
              title: 'Check In the Customer',
              desc: 'Our digital sales platform seamlessly connects customers’ online and in-store experiences into one omnichannel transaction. Sales agents can simply check customers into the showroom to recall their previous online activity and continue where they left off without repeating any steps. This enables ⅓ of customers to finish the transaction in <strong>under 1 hour</strong>.*',
              image: <>
                <img loading='lazy'  alt='' src={CheckIn2Image} />
                <Box
                  as='img'
                  alt=''
                  src={CheckIn1Image}
                  loading='lazy'
                  css={{
                    willChange: 'transform, opacity',
                    transform: 'translateX(-40%) scale(90%)',
                    transitionProperty: 'transform, opacity',
                    transitionDuration: '500ms',
                    transitionDelay: '500ms'
                  }}
                />
              </>
            },
            {
              title: 'Recommend Vehicles & Compare Trims',
              desc: 'Our <strong>Vehicle Recommender</strong> makes it simple for sales agents to conduct a needs assessment for their customers and find inventory that is an exact or near match for their criteria. It also allows them to educate customers about the differences between trim levels.'
            },
            {
              title: 'Highlight the Vehicle’s Features',
              desc: 'Before the customer takes a test drive, sales agents can use their Express Store to do a <strong>vehicle presentation</strong>. All of the information a customer could want to know about any piece of inventory is readily available for the sales agent to reference.'
            },
            {
              title: 'Pencil Deals Side by Side',
              desc: 'Sales Agents can guide customers as they build their deal on a tablet or at a kiosk. They can point out how different payment options, down payment amounts and terms change the monthly payment. They can introduce F&I products to encourage customers to add them to their order. And they can display a transparent price breakdown that builds customer trust.'
            },
            {
              title: 'Compare Payments & Make Adjustments',
              desc: 'Sales agents can use our platform to compare payment options based on the customer’s inputs. They can also roll to payment and push the deal structure into their CRM, as well as into DealerTrack or RouteOne for decisioning. If authorized by a manager, sales agents can make real-time price adjustments, discounting the vehicle by an amount predetermined by the dealership.',
              image: ComparePaymentsImage
            },
            {
              title: 'Streamline the Sale',
              desc: 'When purchasing in the showroom, customers can use their mobile device to complete their credit application, select F&I products, shop OEM accessories and snap photos of their driver’s license. This saves everyone time.'
            },
            {
              title: 'Save Deals & Share Details',
              desc: 'Customers and sales agents can save deals within the Customers tab of Roadster Admin. Agents can use our Share Details feature to send customers a link to their deal sheet, so they can finish the checkout process online when it’s convenient for them, if they prefer.',
              image: SaveDealsImage
            }
          ]}
        />
        <p className='text-right text-silver text-sm mt-5 sm:pt-4 mb-0'>
          *Source: Roadster & NADA, “Dealer Impact Study: Part 2,” September 2020.
        </p>
      </Container>
    </div>
    <div className='Section Section--stripe pb-3 sm:pb-4'>
      <Container size='lg'>
        <h2 className='RuleHed mt-0 mb-4 sm:mb-5'>
          Add-On Product<sup>*</sup>
        </h2>
        <div className='grid gap-4 sm:gap-5'>
          <div className='grid gap-3 sm:gap-4 lg:gap-5 sm:grid-cols-2 items-center'>
            <div>
              <StaticImage
                alt=''
                src='../../images/related_products_et.png'
                placeholder='blurred'
              />
            </div>
            <div className='text-base sm:text-md leading-relaxed'>
              <h3 className='text-lg sm:text-xl lg:text-2xl font-semi-bold mt-1'>
                Express Trade<sup className='tm'>®</sup>
              </h3>
              <p>
                Our proprietary <span className='font-semi-bold'>trade-valuation product</span> uses mobile-first technology to simplify the way customers submit their vehicle info. It gives you complete control over the vehicle’s value and streamlines the process so you can present customers with a firm offer within minutes.
              </p>
              <p>
                <Link
                  to='/products/trade_in_appraisal'
                  className='p-1 -m-1 font-semi-bold'
                  variant='muted'
                  arrowDirection='right'
                  children='Learn more'
                />
              </p>
            </div>
          </div>
          <p className='text-right'>
            <Link
              to='/products/digital_retailing'
              variant='muted'
              className='underline'
              children='*Requires Express Storefront'
            />
          </p>
        </div>
      </Container>
    </div>
    <div className='Section Section--dark'>
      <Container>
        <DemoForm invert={true} />
      </Container>
    </div>
  </Layout>
)

export default HiwShowroomToolsPage
